// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rM d_gv d_cs";
export var heroHeaderCenter = "t_gw d_gw d_cs d_dw";
export var heroHeaderRight = "t_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "t_rN d_gr d_cw";
export var heroParagraphCenter = "t_gs d_gs d_cw d_dw";
export var heroParagraphRight = "t_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "t_rP d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "t_rQ d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "t_rR d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "t_rS d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "t_rT d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "t_rV z_rV";
export var heroExceptionNormal = "t_rW z_rW";
export var heroExceptionLarge = "t_rX z_rX";
export var Title1Small = "t_rY z_rY z_sk z_sl";
export var Title1Normal = "t_rZ z_rZ z_sk z_sm";
export var Title1Large = "t_r0 z_r0 z_sk z_sn";
export var BodySmall = "t_r1 z_r1 z_sk z_sD";
export var BodyNormal = "t_r2 z_r2 z_sk z_sF";
export var BodyLarge = "t_r3 z_r3 z_sk z_sG";