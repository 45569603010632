// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "v_r4 d_bD";
export var storyRowWrapper = "v_hx d_hx d_bK";
export var storyLeftWrapper = "v_r5 d_bz d_bP";
export var storyWrapperFull = "v_r6 d_cD";
export var storyWrapperFullLeft = "v_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "v_mv d_hy";
export var storyLeftWrapperCenter = "v_r7 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "v_r8 d_hF";
export var storyHeader = "v_r9 d_hD d_w d_cs";
export var storyHeaderCenter = "v_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "v_hB d_hB d_by d_dw";
export var storyBtnWrapper = "v_sb d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "v_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "v_sc d_fg d_Z";
export var imageWrapperFull = "v_sd d_w d_H d_bf d_Z";
export var SubtitleSmall = "v_qN z_qN z_sk z_sw";
export var SubtitleNormal = "v_qP z_qP z_sk z_sx";
export var SubtitleLarge = "v_qQ z_qQ z_sk z_sy";
export var textLeft = "v_dv";
export var textCenter = "v_dw";
export var textRight = "v_dx";