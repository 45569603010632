// extracted by mini-css-extract-plugin
export var lbContainerOuter = "F_vk";
export var lbContainerInner = "F_vl";
export var movingForwards = "F_vm";
export var movingForwardsOther = "F_vn";
export var movingBackwards = "F_vp";
export var movingBackwardsOther = "F_vq";
export var lbImage = "F_vr";
export var lbOtherImage = "F_vs";
export var prevButton = "F_vt";
export var nextButton = "F_vv";
export var closing = "F_vw";
export var appear = "F_vx";